nav{
    height: 8vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    background-color: var(--primary);
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.Addbutton{
    background-color: var(--secondary);
    padding: 0.8%;
    padding-left: 1.2%;
    font-size: 12px;
    padding-right: 1.2%;
    border-radius: 8px;
}

.Filters{
    margin-top: 2%;
    height: 10vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Codelist{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2%;
    height: 415px;
    overflow: auto;
}

.CodeCard{
    border: 1px solid #0000004d;
    width: 40%;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    padding: 39px;
    height: fit-content;
}

.CodeCard span{
    font-weight: 400;
    font-size: 12px;
}

.CodeCard h4{
    font-weight: 700;
    color: var(--primary);
    margin: 0;
    font-size: 22px;
}

#ActionButtons{
    margin-left: 4%;
}


@media (max-width:860px) and (min-width:680px) {

    .CodeCard{
        width: 100%;
        margin-bottom: 3%;
    }
    .Codelist{
        height: 850px;
    }
    
}

@media (max-width:680px) {

    .CodeCard{
        width: 100%;
        margin-bottom: 5%;
    }
    .Codelist{
        height: 505px;
    }

    #ActionButtons{
        margin-left: 0%;
        margin-top: 10%;
    }

    .Addbutton {    
        padding: 2.8%;  
        padding-right: 2.2%;
    }
    
    
}