:root{
    --primary:#1576bd;
    --secondary:#25ade0;
}

body{
    margin: 0px;
    font-family: 'Work Sans', sans-serif;
}

